/* eslint-disable consistent-return */
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';
import qs from 'qs';

// constact
import { API_TRADING_URL, API_TRADING_URL_V2 } from 'constants/api';

// handler
import securitiesLocalStorage from 'utils/securitiesLocalStorage';
import { sentryLog } from 'utils/logger';
import {
  handleNoResponse,
  tradingErrorStatusHandlerList,
  handleShowAlertMessage,
} from './errorHandler';
import { getErrorConfig, getPathFromUrl } from './utils';
import { CUSTOM_HIDDEN_ALERT_URL } from './constants';

const onRequest = (
  config: AxiosRequestConfig,
  urlEncoded: boolean,
): AxiosRequestConfig => {
  const { securitiesAccessToken } = securitiesLocalStorage.getSecuritiesToken();
  const { method, headers, url, data, withAccessToken = true } = config;

  // assign access token
  if (securitiesAccessToken && withAccessToken) {
    headers.Authorization = `Bearer ${securitiesAccessToken}`;
  }

  // immidiately return the config if using the new trading API
  const isTradingAPIV2 = url.includes(API_TRADING_URL_V2); // `/`

  if (isTradingAPIV2) {
    return config;
  }

  /**
   * @deprecated
   * we check wether the request is from the legacy API_TRADING_URL
   */
  const isLegacyTradingAPI = url.includes(API_TRADING_URL);
  /**
   * @deprecated
   * we check wether the request using POST (post) method
   */
  const isPostRequest = method === 'POST' || method === 'post';
  /**
   * @deprecated
   * Check if user is using the legacy API,
   * We will override the content-type header config
   */
  if ((isLegacyTradingAPI && isPostRequest) || urlEncoded) {
    headers['content-type'] = 'application/x-www-form-urlencoded';
    // eslint-disable-next-line no-param-reassign
    config.data = qs.stringify(data);
  }

  return config;
};

const onResponseError = async (
  error: AxiosError,
  isShowAlertMessage: boolean,
): Promise<AxiosError> => {
  const { response, request } = error;
  const { status } = getErrorConfig(error);

  sentryLog({
    scope: 'server-layer',
    level: 'error',
    httpStatusCode: status,
    tags: [
      { title: 'api-error-url', value: request?.responseURL },
      { title: 'api-error-text', value: request?.responseText },
    ],
    error,
  });

  if (!response && isShowAlertMessage) return handleNoResponse(error);

  // Do not print 401 error logout
  if (status === 401 && request?.responseURL?.includes('auth/logout')) {
    return Promise.reject(error);
  }

  // check api
  const apiUrl = getPathFromUrl(error.response.config.url);
  const isNoNeedToRefreshToken = status !== 401 && error.response.data;

  const isHideAlertOnCustomUrl = CUSTOM_HIDDEN_ALERT_URL.includes(apiUrl);
  if (!isShowAlertMessage && isHideAlertOnCustomUrl && isNoNeedToRefreshToken) {
    return Promise.reject(error);
  }

  const statusHandler = tradingErrorStatusHandlerList[status];
  if (statusHandler) {
    return statusHandler(error);
  }

  return handleShowAlertMessage(error);
};

const request = (
  baseURL: string = API_TRADING_URL,
  isShowAlertMessage = true,
  urlEncoded?: boolean,
): AxiosInstance => {
  const apiInstance = axios.create({
    baseURL,
    headers: { accept: 'application/json' },
  });

  apiInstance.interceptors.request.use((config) =>
    onRequest(config, urlEncoded),
  );

  apiInstance.interceptors.response.use(
    (response) => response,
    (error) => onResponseError(error, isShowAlertMessage),
  );

  return apiInstance;
};

export default request;
