import requestExodus from 'config/requestExodus';
import { AcceptanceLanguageEnum } from 'global/Securities/types/termsAndCondition';

const exodusInstance = {
  defaultInstance: requestExodus(),
  noAlert: requestExodus(false),
  withLanguageInstance: (
    language: AcceptanceLanguageEnum = AcceptanceLanguageEnum.BAHASA,
    disableErrorAlert = false,
  ) =>
    requestExodus(disableErrorAlert, undefined, false, {
      'Accept-Language': language,
    }),
};

export default exodusInstance;
