import { AcceptanceLetter } from './api/termsAndConditions';

export enum AcceptanceLanguageEnum {
  BAHASA = 'ID',
  ENGLISH = 'EN',
}

/*
  The TnC Feature ID is based on their index,
  so we don't need assign value to it
*/
export enum TnCFeatureIDEnum {
  FEATURE_UNSPECIFIED,
  FEATURE_TRADING_LIMIT,
}

interface FeatureAcceptanceContent {
  isAccepted: boolean;
  acceptanceLetters: AcceptanceLetter[];
  html?: string;
}

export type FeatureAcceptance = Record<
  TnCFeatureIDEnum,
  FeatureAcceptanceContent
>;

// terms and condition v2
export enum SubAccountType {
  DAY_TRADE = 'SUB_ACCOUNT_TYPE_DAY_TRADE',
}

export enum SubAccountStatus {
  ACTIVE = 'SUB_ACCOUNT_STATUS_ACTIVE',
}

export enum TnCFeaturesIDEnum {
  TRADING_LIMIT = 'FEATURE_ID_TRADING_LIMIT',
  AUTO_ORDER = 'FEATURE_ID_AUTO_ORDER',
  DAY_TRADE = 'FEATURE_ID_DAY_TRADE',
  MULTI_PORTO = 'FEATURE_ID_MULTIPLE_PORTFOLIO',
}

export const TnCFeaturePersistMap = {
  [TnCFeaturesIDEnum.TRADING_LIMIT]: 'tradingLimitTnc',
  [TnCFeaturesIDEnum.AUTO_ORDER]: 'autoOrderTnc',
  [TnCFeaturesIDEnum.DAY_TRADE]: 'dayTradeTnc',
};

interface FeatureAcceptancesContent {
  isAccepted: boolean;
  url: string;
  version: number;
}

export type FeatureAcceptances = Record<
  TnCFeaturesIDEnum,
  FeatureAcceptancesContent
>;
