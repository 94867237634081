import { message } from 'antd';
import Flex from '@deprecated-ui/common/Flex';
import Image from 'global/Image';
import HtmlParser from 'html-react-parser';

type MessageType = 'success' | 'warning' | 'info' | 'error';
type AlertType =
  | 'default'
  | 'snackbar'
  | 'plain'
  | 'plain-default'
  | 'snackbar-dark';

const alertIcon = {
  success: 'alert-success.svg',
  warning: 'alert-warning.svg',
  info: 'alert-info.svg',
  error: 'alert-error.svg',
};

interface IAlertMessage {
  content: string;
  messageType: MessageType;
  alertType: AlertType;
  hasCloseIcon?: boolean;
  duration?: number;
  onCloseHandler?: () => void;
}

function alertMessage({
  content,
  messageType,
  alertType,
  hasCloseIcon = false,
  duration = 3,
  onCloseHandler = () => {},
}: IAlertMessage) {
  message.destroy();

  let Icon = null;

  if (alertType === 'snackbar' || alertType === 'snackbar-dark') {
    Icon = <Image src={`/icon/${alertIcon[messageType]}`} />;
  }

  const closeIcon = hasCloseIcon ? (
    <Image src="/icon/alert-close.svg" onClick={() => message.destroy()} />
  ) : null;

  message.open({
    type: messageType,
    content: (
      <div
        className={`message-${messageType} alert-${alertType}`}
        data-cy="global-alert-message"
      >
        <Flex align="center">
          {Icon}
          <span>{HtmlParser(content)}</span>
          {closeIcon}
        </Flex>
      </div>
    ),
    duration,
    style: {
      top: '50px',
      fontFamily: 'ProximaNovaRg',
      left: 0,
      margin: 'auto',
      position: 'fixed',
      right: 0,
    },
    icon: null,
    className: 'sysmsg-alert',
    onClick: () => message.destroy(),
    onClose: onCloseHandler,
  });

  return null;
}

export default alertMessage;
