import { AxiosError } from 'axios';
import { Response } from '../../../@types/api-response';

export const getErrorConfig = (
  error: AxiosError<Response>,
): TradingErrorConfig => {
  const { data, status } = error.response || {};
  const { error_type: type, message } = data || {};

  return { status, type, message };
};

export const getPathFromUrl = (url: string) => {
  try {
    const urlObj = new URL(url);
    const { pathname } = urlObj;
    const pathParts = pathname.replace(/^\/+|\/+$/g, '').split('/');
    return pathParts.join('/');
  } catch (error) {
    return null;
  }
};
